<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
      <div class="list_top">
        <Title :title="title[0]"></Title>
        <div class="userinfo">
          <div class="avatar_box">
            <!-- <img src="@/assets/images/avatar.jpg" class="avatar" /> -->
            <img :src="getUserInfo.sellerAvatar" class="avatar" />
          </div>
          <div class="username">你好，{{getUserInfo.mot}}</div>
        </div>
      </div>

      <div class="list_bottom">
        <div class="b_top">
          <div class="storetitle">
            <Title :title="title[1]"></Title>
          </div>
          <el-button
            type="primary"
            class="haowu hw-add addstore"
            @click="addStore"
          >&nbsp;&nbsp;添加绑定店铺</el-button>
        </div>

        <!-- 列表区域  border加边框 stripe隔行变色 -->
        <el-table :data="getStoreList" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
          <el-table-column type="index" label="序号" width="50px"></el-table-column>
          <el-table-column label="平台" prop="platform"></el-table-column>
          <el-table-column label="店铺名称" prop="shopsname"></el-table-column>
          <el-table-column label="旺旺名称" prop="wwname"></el-table-column>
          <el-table-column label="店铺地址" prop="">
            <template v-slot:default="scope">
                   <a class="view_address" @click="viewStoreAddress(scope.row.id)">查看店铺地址</a>
            </template>
          </el-table-column>
          <el-table-column label="发货信息" prop="address" width="300px"></el-table-column>
          <el-table-column label="店铺复购时间(天)" prop="againbuy"></el-table-column>
          <el-table-column label="审核状态" prop="state">
            <template v-slot:default="scope">
              <el-tag type="success" v-if="scope.row.state==1">已通过</el-tag>
              <el-tag type="warning" v-else-if="scope.row.state==2">审核中</el-tag>
              <el-tag type="danger" v-else>未通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="审核意见" prop="feedback"></el-table-column>
          <el-table-column label="操作" width="280px">
              <template v-slot:default="scope">
                     <!--修改发货信息-->
                     <el-button type="primary" icon="el-icon-edit" size="small" @click="edit_FHDialog(scope.row.id)">发货信息</el-button>
                     <!--修改发货信息-->
                     <el-button type="warning" icon="el-icon-edit-outline" size="small" @click="edit_RepurchaseDialog(scope.row.id ,scope.row.againbuy)">复购时间</el-button>
              </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页区域 -->
      <el-pagination
        :current-page="queryInfo.pagenum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="getPageSizes"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="getTotal"
      ></el-pagination>
    </el-card>

    <!-- 添加店铺对话框 -->
    <el-dialog title="填写店铺信息" :visible.sync="addStoreDialogVisible" width="50%" @close="addStoreDialogClosed">
      <!--内容主体区域-->
      <el-form status-icon :model="BindStoreForm" :rules="BindStoreRules" label-width="110px" ref="BindStoreFormRef">
        <el-form-item label="请选择平台" prop="platformValue">
          <el-select v-model="BindStoreForm.platformValue" placeholder="请选择平台" @visible-change="getPlatformName">
            <el-option v-for="item in platformList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="店铺名称" prop="storeName">
          <el-input v-model="BindStoreForm.storeName" placeholder="请输入店铺名称" class="ys"></el-input>
          <p class="tips">（务必跟手机端宝贝页显示的店铺一致)</p>
        </el-form-item>

        <el-form-item label="店铺首页网址" prop="storeAddress">
          <el-input v-model="BindStoreForm.storeAddress" placeholder="请输入店铺首页网址"  style="width:500px"></el-input>
          <p class="tips">（店铺首页地址绑定后无法修改)</p>
        </el-form-item>

        <el-form-item label="店铺旺旺ID" prop="WWID">
          <el-input v-model="BindStoreForm.WWID" placeholder="请输入店铺主旺旺ID" class="ys"></el-input>
          <p class="tips">（店铺主旺旺ID绑定后无法修改）</p>
        </el-form-item>

        <el-form-item label="发货地" class="form-item" required>
             <el-col :span="6">
                 <el-form-item prop="provinceDataValue">
                        <el-select v-model="BindStoreForm.provinceDataValue"  filterable placeholder="请选择省份" style="margin-right:5px" @visible-change="getProvinceData" @change="changeProvince($event)">
                             <!-- <el-option value="" label="请选择省份"></el-option> -->
                            <el-option :value="item.bh" :label="item.name1" v-for="item in provinceData" :key="item.id"></el-option>
                        </el-select>
                 </el-form-item>
             </el-col>
                  
            <el-col :span="6">
                  <el-form-item prop="cityDataValue">
                          <el-select  v-model="BindStoreForm.cityDataValue" filterable placeholder="请选择城市" style="margin-right:5px" @change="changeCity($event)">
                              <!-- <el-option value="" label="请选择城市"></el-option> -->
                              <el-option :value="item.bh" :label="item.name1" v-for="item in cityData" :key="item.id"></el-option>
                         </el-select>
                  </el-form-item>
            </el-col>
    

            <el-col :span="6">
                  <el-form-item prop="areaDataValue">
                           <el-select  v-model="BindStoreForm.areaDataValue" filterable placeholder="请选择区县">
                                <!-- <el-option value="" label="请选择区县"></el-option> -->
                                <el-option :value="item.bh" :label="item.name1" v-for="item in areaData" :key="item.id"></el-option>
                           </el-select> 
                  </el-form-item>
            </el-col>
         
        </el-form-item>

        <el-form-item label="街道地址" prop="streetAddress">
          <el-input v-model="BindStoreForm.streetAddress" placeholder="请输入详细街道地址" style="width:500px"></el-input>
        </el-form-item>
        
        <el-form-item label="店铺负责人" prop="storeManager">
          <el-input v-model="BindStoreForm.storeManager" placeholder="请输入店铺负责人姓名" class="ys"></el-input>
        </el-form-item>

        <el-form-item label="发货人电话" prop="telephoneNumber">
          <el-input v-model="BindStoreForm.telephoneNumber" placeholder="请输入发货电话" class="ys"></el-input>
        </el-form-item>

     
        <el-form-item label="复购间隔" prop="time">
               <el-input v-model.number="BindStoreForm.time" placeholder="请输入复购间隔" class="ys">
                    <span slot="append">天</span>
               </el-input>
               <p class="tips">（间隔设置区间要求“大于等于15天，小于等于9999天”,系统默认是15天）</p>
        </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
         <el-button type="primary" class="haowu hw-queren" @click="addBindStoreSubmit"> 确定提交</el-button>
        <el-button @click="addStoreDialogVisible = false"  type="danger" class="haowu hw-quxiao"> 取消退出</el-button>
      </span>
    </el-dialog>

    <!--修改发货信息的对话框-->
    <el-dialog title="修改店铺地址信息" :visible.sync="FHDialogVisible" width="50%" @close="editFHInfoDialogClosed">
      <!--内容主体区域-->
      <el-form :model="BindStoreForm" status-icon :rules="editStoreFHInfoRules" ref="editFHInfoFormRef" label-width="100px">
        <el-form-item label="发货地" class="form-item" required>
             <el-col :span="6">
                 <el-form-item prop="provinceDataValue">
                        <el-select v-model="BindStoreForm.provinceDataValue"  filterable placeholder="请选择省份" style="margin-right:5px" @visible-change="getProvinceData" @change="changeProvince($event)">
                             <!-- <el-option value="" label="请选择省份"></el-option> -->
                            <el-option :value="item.bh" :label="item.name1" v-for="item in provinceData" :key="item.id"></el-option>
                        </el-select>
                 </el-form-item>
             </el-col>
                  
            <el-col :span="6">
                  <el-form-item prop="cityDataValue">
                          <el-select  v-model="BindStoreForm.cityDataValue" filterable placeholder="请选择城市" style="margin-right:5px" @change="changeCity($event)">
                              <!-- <el-option value="" label="请选择城市"></el-option> -->
                              <el-option :value="item.bh" :label="item.name1" v-for="item in cityData" :key="item.id"></el-option>
                          </el-select>
                  </el-form-item>
            </el-col>
    

            <el-col :span="6">
                  <el-form-item prop="areaDataValue">
                           <el-select  v-model="BindStoreForm.areaDataValue" filterable placeholder="请选择区县">
                                <!-- <el-option value="" label="请选择区县"></el-option> -->
                                <el-option :value="item.bh" :label="item.name1" v-for="item in areaData" :key="item.id"></el-option>
                           </el-select> 
                  </el-form-item>
            </el-col>
         
        </el-form-item>

        <el-form-item label="街道地址" prop="streetAddress">
          <el-input  style="width:500px" v-model="BindStoreForm.streetAddress"></el-input>
        </el-form-item>
        <el-form-item label="发货人姓名" prop="storeManager">
          <el-input  class="ys" v-model="BindStoreForm.storeManager"></el-input>
        </el-form-item>
        <el-form-item label="发货人电话" prop="telephoneNumber" >
          <el-input  class="ys" v-model="BindStoreForm.telephoneNumber"></el-input>
        </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="haowu hw-queren"  @click="editStoreFHInfoSubmit"> 确定提交</el-button>
        <el-button @click="FHDialogVisible = false"  type="danger" class="haowu hw-quxiao"> 取消退出</el-button>
      </span>
    </el-dialog>

    <!-- 修改复购时间 -->
    <el-dialog title="修改复购时间址信息" :visible.sync="RepurchaseDialogVisible" width="50%" @close="editRepurchaseDialogClosed">
      <!--内容主体区域-->
      <el-form status-icon :model="BindStoreForm" :rules="editRepurchaseDRules" ref="editRepurchaseFormRef" label-width="100px">
              <div class="text_list">
                  <p class="list_l">限制时间指用户第一次接任务与第二次接任务之间的时间间隔，不同类目的商家，用户的平均回购时间都不同，适当比例的用户回购对店铺和商品权重有很大好糊，所以我们开放了商家店铺对用户的间隔时间设置，大家可以更具自己类目和店铺的需要在规定区间内设置.</p>
                  <p class="list_l">(注意：商家一定要更具自己的类目特点来设置，比如食品类正常回购时间就很短，大家电类就会很长。如果设置了过短且不符合类目特性间隔时间，可能会触发淘宝<span style="color: #ff7369;">“重复购买”！</span>的降权触发，大家慎重设置</p>
              </div>
              <div class="text_tips">
                    <span class="tip">间隔设置区间要求“大于等于15天，小于等于9999天”系统默认是15天</span>
              </div>

              <el-form-item label="复购间隔" prop="time" style="margin-top:20px">
                     <el-input placeholder="请输入复购间隔" style="width:200px" v-model.number="BindStoreForm.time">
                          <span slot="append">天</span>
                     </el-input>
              </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="haowu hw-queren"  @click="editRepurchaseIntervalSubmit"> 确定提交</el-button>
        <el-button @click="RepurchaseDialogVisible = false"  type="danger" class="haowu hw-quxiao"> 取消退出</el-button>
      </span>
    </el-dialog>

    <!-- 店铺地址对话框 -->
    <el-dialog title="绑定的店铺地址" :visible.sync="viewStoreAddressDialogVisible" width="50%">
      <!--内容主体区域-->
      <el-form label-width="100px">
           <p class="storeLink">{{bindStoreAddress}}</p>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters,mapState } from 'vuex'
import { checkEmail,checkMobile,checkday,checkDay } from '@/utils/validate'
export default {
  data() {
    return {
      title: ["基本信息", "店铺信息"],
      //面包屑数据
      breadcrumbData:[ 
          {
              id:1,
              title:'好物电商',
              path:'',
              isClick:false,
          },
          {
              id:2,
              title:'店铺管理',
              path:'',
              isClick:false,
          },
          {
              id:3,
              title:'绑定店铺',
              path:'bindstore',
              isClick:true,
          }
      ],
      //获取用户列表的参数对象
      queryInfo: {
        // 当前的页数
        pagenum: 1,
        // 当前每一页显示多少条数据
        pagesize: 10
      },
      //添加用户的表单数据
      BindStoreForm: {
            platformValue: "",//平台默认值
            storeName:"",//店铺名称
            storeAddress:"",//店铺首页地址
            WWID:"",//店铺主旺旺ID
            provinceDataValue:'', //省
            cityDataValue:'',  //城市
            areaDataValue:'',  //县区
            streetAddress:"",//街道地址
            storeManager:"",//店铺负责人
            telephoneNumber:"",//发货人电话
            time:"",//复购时间
      },
      //控制修改发货信息对话框的显示与隐藏
      FHDialogVisible: false,
      //控制添加店铺对话框的现实与隐藏
      addStoreDialogVisible: false,
      //控制修改复购时间对话框的显示与隐藏
      RepurchaseDialogVisible:false,
      //控制绑定店铺地址对话框的显示与隐藏
      viewStoreAddressDialogVisible:false,
      //绑定店铺表单的验证规则对象
      BindStoreRules:{
        platformValue: [
          {required: true, message: "请选择平台", trigger: "change", type:'number' },
        ],
        storeName: [
          { required: true, message: "店铺名不能为空", trigger: "blur" },
        ],
        storeAddress: [
          { required: true, message: "店铺网址不能为空", trigger: "blur" },
        ],
        WWID: [
          { required: true, message: "旺旺ID不能为空", trigger: "blur" },
        ],
        provinceDataValue: [
          { required: true, message: "请选择发货所在的省份", trigger: "change" },
        ],
        cityDataValue: [
          { required: true, message: "请选择发货所在城市", trigger: "change" },
        ],
        areaDataValue: [
          { required: true, message: "请选择发货所在的地区", trigger: "change" },
        ],
        streetAddress: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        storeManager: [
          { required: true, message: "请输入店铺负责人姓名", trigger: "blur" },
        ],
        telephoneNumber: [
          { required: true, message: "电话不能为空", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" }
        ],
        time: [
          // { required: true, message: "请输入复购时间", trigger: "blur" },
          { validator: checkday, trigger: "blur" }
        ],
      },
      //修改发货信息表单的验证规则对象
      editStoreFHInfoRules:{
           provinceDataValue: [
             { required: true, message: "请选择发货所在的省份", trigger: "change" },
           ],
           cityDataValue: [
             { required: true, message: "请选择发货所在城市", trigger: "change" },
           ],
           areaDataValue: [
             { required: true, message: "请选择发货所在的地区", trigger: "change" },
           ],
           streetAddress: [
             { required: true, message: "详细地址不能为空", trigger: "blur" },
           ],
           storeManager: [
             { required: true, message: "请输入店铺负责人姓名", trigger: "blur" },
           ],
           telephoneNumber: [
             { required: true, message: "电话不能为空", trigger: "blur" },
             { validator: checkMobile, trigger: "blur" }
           ],
      },
      //修改复购时间表单的验证规则对象
      editRepurchaseDRules:{
            time: [
                { validator: checkDay, trigger: "blur" }
            ],
      },
      //选中商铺的ID
      selectedShopID:-1,
     

    };
  },
  computed:{
      ...mapGetters(['getStoreList','getUserInfo','getPageSizes','getTotal']),
      ...mapState({
          //获取绑定店铺地址链接
          bindStoreAddress:state => state.bindStore.bindStoreAddress.shopsurl, 
          //获取省份数据
          provinceData:state => state.bindStore.provinceData,
          //获取城市数据
          cityData:state => state.bindStore.cityData,
          //获取区县数据
          areaData:state => state.bindStore.areaData,
          //获取平台名称数据
          platformList:state => state.bindStore.platformList
      })

  },
  methods: {
    //获取店铺列表数据
    getStoreInfo(){
         this.$store.dispatch('getStoreInfo',{ num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})
    },
    
    //展示添加店铺对话框
    addStore() {
       this.addStoreDialogVisible = true;
    },
    
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.queryInfo.pagenum = 1;
      this.getStoreInfo();
    },
    
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getStoreInfo();
    },

    //展示绑定店铺地址对话框
    viewStoreAddress(id){
           this.viewStoreAddressDialogVisible = true
           this.$store.dispatch('getBindStoreAddress',{shopsid:id})
    },

    //获取省份
    getProvinceData(){
          this.$store.dispatch('getProvinceData')
    },
    //切换省份
    changeProvince(bh){
          this.BindStoreForm.cityDataValue=''
          this.BindStoreForm.areaDataValue=''
          this.$store.dispatch('getCityData',bh)
          
    },
    //切换城市
    changeCity(bh){
          this.BindStoreForm.areaDataValue=''
          this.$store.dispatch('getAreaData',bh)
    },
    //获取平台名称列表
    getPlatformName(){
         this.$store.dispatch('getPlatformName')
    },

    //监听添加绑定店铺对话框的关闭事件
    addStoreDialogClosed(){
        this.$refs.BindStoreFormRef.resetFields();
    },

    //添加绑定店铺提交
    addBindStoreSubmit(){
         this.$refs.BindStoreFormRef.validate(async valid => {
              if(!valid) return
              try{
                   await this.$store.dispatch('addBindStore',this.BindStoreForm)
                   this.$message.success("绑定店铺成功！");
                   this.addStoreDialogVisible = false;
                   this.addStoreDialogClosed()
                   this.getStoreInfo()
              }catch(error){
                   this.$message.error("绑定店铺失败，请稍后重试！");
                   console.log(error.message)
              }
         })
    },

    //展示修改复购时间对话框
    edit_RepurchaseDialog(shopsid,time){
       this.RepurchaseDialogVisible = true
       this.BindStoreForm.time = time
       this.selectedShopID = shopsid
    },

    //修改复购间隔
    editRepurchaseIntervalSubmit(){
          this.$refs.editRepurchaseFormRef.validate(async valid => {
                  if(!valid) return
                  try{
                        const { selectedShopID } = this
                        const { time } = this.BindStoreForm
                        await this.$store.dispatch('editRepurchaseInterval',{shopsid:selectedShopID,againbuy:time})
                        this.$message.success("修改成功！");
                        this.RepurchaseDialogVisible = false
                        this.editRepurchaseDialogClosed()
                        this.getStoreInfo()
                  }catch(error){
                        this.$message.error("修改失败！");
                        console.log(error.message)
                  }
        })
          
    },

    //监听修改复购间隔对话框的关闭事件
    editRepurchaseDialogClosed(){
        this.$refs.editRepurchaseFormRef.resetFields();
        this.selectedShopID = -1
        this.BindStoreForm.time = ''
    },

    //展示修改发货信息对话框
    async edit_FHDialog(shopsid) {
        this.selectedShopID = shopsid;
        try{
             await this.$store.dispatch('getStoreFHInfo',shopsid)
             this.BindStoreForm.provinceDataValue = this.$store.state.bindStore.storeFHInfo.province
             this.BindStoreForm.cityDataValue = this.$store.state.bindStore.storeFHInfo.city
             this.BindStoreForm.areaDataValue = this.$store.state.bindStore.storeFHInfo.town
             this.BindStoreForm.streetAddress = this.$store.state.bindStore.storeFHInfo.detailedaddress
             this.BindStoreForm.storeManager = this.$store.state.bindStore.storeFHInfo.person
             this.BindStoreForm.telephoneNumber = this.$store.state.bindStore.storeFHInfo.mot
             this.FHDialogVisible = true;
        }catch(error){
             console.log(error.message)
        }
    },

    //修改商铺发货信息
    editStoreFHInfoSubmit(){
         this.$refs.editFHInfoFormRef.validate(async valid => {  
             if(!valid) return
             try{
                   const { provinceDataValue,cityDataValue,areaDataValue,streetAddress,storeManager,telephoneNumber } = this.BindStoreForm
                   const { selectedShopID } = this
                   await this.$store.dispatch('editStoreFHInfo',{province:provinceDataValue,city:cityDataValue,town:areaDataValue,detailedaddress:streetAddress,person:storeManager,mot:telephoneNumber,shopsid:selectedShopID})
                   this.$message.success("修改成功！");
                   this.FHDialogVisible = false
                   this.editFHInfoDialogClosed()
                   this.getStoreInfo()
             }catch(error){
                    this.$message.error("修改失败！");
                   console.log(error.message)
             }

         })
    },

    //监听修改发货信息对话框关闭时间
    editFHInfoDialogClosed(){
        this.$refs.editFHInfoFormRef.resetFields();
        this.selectedShopID = -1
        this.BindStoreForm.time = ''
        this.BindStoreForm.provinceDataValue = ''
        this.BindStoreForm.cityDataValue = ''
        this.BindStoreForm.areaDataValue = ''
        this.BindStoreForm.streetAddress = ''
        this.BindStoreForm.storeManager = ''
        this.BindStoreForm.telephoneNumber = ''
    },




    

  },
  created(){
     this.getStoreInfo()
  }
};
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.list_top {
  width: 100%;
  height: auto;
  // background: chartreuse;
  .userinfo {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    padding-left:10px;
    .avatar_box {
      width: 60px;
      height: 60px;
      // background: crimson;
      // border:2px solid #409eff;
      border:2px solid rgba(@themeColor,1);
      border-radius: 50%;
      overflow: hidden;
      .avatar {
        width: 60px;
        height: 60px;
      }
    }
    .username {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.list_bottom {
  width: 100%;
  height: auto;
  // background: #00ffff;
  margin-top: 5px;
  padding-top: 30px;
  .b_top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .storetitle {
      width: 100px;
    }
    .addstore {
      margin-left: 5px;
    }
  }
  .el-table {
    margin-top: 20px;
  }
}
.el-card {
  margin: 20px 20px 50px 20px;
}
.el-pagination {
  margin-top: 20px;
}
.ys{
  width: 300px;
}
.tips {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #a2a2a2;
    margin:0 !important;
}
.dialog-footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.text_list{
    .list_l{
       font-size: 18px;
       font-weight: 400;
       line-height: 32px;
       color: #a2a2a2;
       margin:0 !important;
    }
}
.text_tips{
       margin-top: 20px;
       .tip{
           padding: 9px 20px;
           font-size: 18px;
           line-height: 32px;
           color: #232323;
           background: #e6e6e6;
           border-radius: 4px;
       }
}
.view_address{
  //  color: #409eff;
   color:@themeColor;
   cursor: pointer;
   &:hover{
       color: #ff0000;
   }
}
.storeLink{
   line-height: 36px;
}

</style>